import './ProductPage.css';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import MarkdownSpan from "../MarkdownSpan/MarkdownSpan";

export default function ProductPage({ getProduct, data, cart, handleAddToCart, handlePlusToCart, handleMinusFromCart }) {
    const { productId } = useParams();

    const isInCart = !!cart.length && !!data && !!cart.find((item) => item.metadata.uid === data.metadata.uid);
    const quantity = isInCart ? cart.find((item) => item.metadata.uid === data.metadata.uid).quantity : 0;


    useEffect(() => {
        getProduct(productId);
    }, []);

    if (data === null) {
        return null;
    }

    return (
        <section id='productPage'>
            <div className="productPage">
                <div className='productHeader'>
                    <h1 className="productName">{data.name}</h1>
                    <div className="buttonAddBox">
                        {!isInCart && <button id='buttonAdd' onClick={() => handleAddToCart(data)} className='productButton'>{data.price} &#8381;</button>}
                        {isInCart && <button disabled id='buttonAdd' onClick={() => handleAddToCart(data)} className='productButton'>{data.price} &#8381;</button>}
                        {isInCart && <div className='cartItemCount'>
                            <button onClick={() => handleMinusFromCart(data)}>-</button>
                            <p>{quantity}</p>
                            <button onClick={() => handlePlusToCart(data)}>+</button>
                        </div>}
                    </div>
                </div>

                <div className='productBody'>
                    <div className='productImageBox'>
                        <img src={data.previewImage} alt={data.name} className='productImage' />
                    </div>
                    
                    <div className='productDescription'>
                        <div >
                            <p><MarkdownSpan markdownText={data?.description ? data.description : ''} /></p>
                        </div>

                        <div className="productTableBox">
                            <table className="productTable">
                                <tbody>
                                    {Object.entries(data.characteristics).map(([key, value]) => (
                                        <tr key={key} className="tableTr">
                                            <td className="tableTd">{key}:</td><td className="tableTd">{value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    )
}
