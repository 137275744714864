import './CatalogTabs.css'
import { useEffect } from "react";
import { Link } from 'react-router-dom';

export default function CatalogTabs({ renderCatalog, categories }) {


    function CatalogCard({ image, subtitle, uid }) {
        return (
            <Link className='main__catalog_item' to={`/Catalog/${uid}`} target='_blank'>
                <img className='main__catalog_img' alt='catalog-1' src={image}></img>
                <h3 className='main__catalog_item_subtitle'>{subtitle}</h3>
            </Link>
        )
    }

    useEffect(() => {
        renderCatalog();
    }, []);

    const mainCategoryCards = categories.slice(0, 3)

    return (
        <section id="main__catalog" className='main__catalog'>
            <h2 className='main__title'>Каталог продукции</h2>
            <div className="main__catalog_Tabs">
                {mainCategoryCards.map((item) => (
                    <CatalogCard
                        key={item.metadata.uid}
                        image={item.previewImage}
                        subtitle={item.name}
                        uid={item.metadata.uid}
                    />
                ))}
            </div>
        </section>
    )
}