import "./Main.css";
import Banner from "./Banner/Banner";
import NewsTabs from "./NewsTabs/NewsTabs";
import CatalogTabs from "./Catalog_tabs/CatalogTabs";


export default function Main({ news, renderNews, renderCatalog, categories }) {
    return (
        <section className="main">
            <Banner />
            <NewsTabs
                renderNews={renderNews}
                news={news}
            />
            <CatalogTabs
                renderCatalog={renderCatalog}
                categories={categories}
            />
        </section>
    )
}
