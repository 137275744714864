import './Admin.css';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import MainApi from '../../utils/MainApi';

export default function Admin({ onLogin, onSubmitPost, onSubmitCategory, onSubmitProduct, renderCatalog, categories }) {
    useEffect(() => {
        renderCatalog();
    }, []);

    function ParentCategory({ parentCategory }) {
        const mainApi = new MainApi();
        const [displayUnderCategories, setdisplayUnderCategories] = useState([]);

        useEffect(() => {
            mainApi.getCategoryByParentUid(parentCategory.metadata.uid)
                .then(setdisplayUnderCategories)
        }, []);

        return (
            <div key={parentCategory.metadata.uid}>
                <div className="catalog__card">
                    <div className="catalog__image_box">
                        <img src={parentCategory.previewImage} className="catalog__image" alt={parentCategory.name} />
                    </div>
                    <div className="catalog__content">
                        <p className="catalog__title">{parentCategory.name}</p>
                        <p>{parentCategory.metadata.uid}</p>
                    </div>
                    <div className='catalog__container_button'>
                    </div>
                </div>
                <p>Подкатегории:</p>
                <ul className='catalog__list'>
                    {displayUnderCategories.map((underCategory) => (<UnderCategory
                        underCategory={underCategory}
                    />))}
                </ul>
            </div>
        )
    }

    function UnderCategory({ underCategory }) {

        return (
            <div className="catalog__card" key={underCategory.metadata.uid}>
                <img src={underCategory.previewImage} className="categoryPageImg" alt={underCategory.name} />
                <div className="catalog__content">
                    <p className="catalog__title">{underCategory.name}</p>
                    <p>{underCategory.metadata.uid}</p>
                </div>
            </div>
        )
    }

    function handleSubmitLogin(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            "username": formData.get("username"), "password": formData.get("password")
        };
        onLogin(data);
    }

    function handleSubmitPost(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            "title": formData.get("title"),
            "previewImage": formData.get("previewImage"),
            "description": formData.get("description"),
            "content": formData.get("content")
        };
        onSubmitPost(data);
    }

    function handleSubmitCategory(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            "previewImage": formData.get('previewImage'),
            "description": formData.get('description'),
            "name": formData.get('name'),
            "parentCategoryUid": formData.get('parentCategoryUid') ? formData.get('parentCategoryUid') : null
        };
        onSubmitCategory(data);
    }

    function handleSubmitProduct(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            "previewImage": formData.get('previewImage'),
            "description": formData.get('description'),
            "name": formData.get('name'),
            "price": formData.get('price'),
            "categoryUid": formData.get('categoryUid'),
            "characteristics": formData.get('characteristics')? JSON.parse(formData.get('characteristics')): {},
        };
        onSubmitProduct(data);
    }

    return (
        <section id="admin">
            <div className='admin'>
                <div className='login'>
                    {/*логин*/}

                    <h1>Authentication: </h1>
                    <form id="authForm" onSubmit={handleSubmitLogin}>
                        <div>
                            <label>Username: </label>
                            <input type="text" name="username" />
                        </div>
                        <div>
                            <label>Password: </label>
                            <input type="password" name="password" />
                        </div>
                        <button type="submit" value="Authenticate">login</button>
                    </form>
                    
                </div>
                <div className='add'>
                    <div className='addBlock'>
                        {/*добавить новость */}

                        <h1>New Post</h1>
                        <form id="createPostForm" onSubmit={handleSubmitPost}>
                            <div>
                                <label>Title: </label>
                                <input type="text" name="title" />
                            </div>
                            <div>
                                <label>Preview image: </label>
                                <input type="text" name="previewImage" />
                            </div>
                            <div>
                                <label>Description: </label>
                                <textarea name="description">
                                </textarea>
                            </div>
                            <div>
                                <label>Content: </label>
                                <textarea name="content">
                                </textarea>
                            </div>
                            <button type="submit" value="Create"> Post</button>
                        </form>
                    </div>

                    <div div className='addBlock'>
                        {/*Добавить категорию */}

                        <h1>Add Category</h1>
                        <form id="addCategory" onSubmit={handleSubmitCategory}>
                            <div>
                                <label>Image: </label>
                                <input type="link" name="previewImage" />
                            </div>
                            <div>
                                <label>Description </label>
                                <textarea type="text" name="description" />
                            </div>
                            <div>
                                <label>Name </label>
                                <input type="text" name="name" />
                            </div>
                            <div>
                                <label>ParentCategory </label>
                                <input type="id" name="parentCategoryUid" />
                            </div>
                            <button type="submit" value="PostCategory">Post</button>
                        </form>
                    </div>

                    <div div className='addBlock'>
                        {/*Добавить продукт */}

                        <h1>Add Product</h1>
                        <form id="addProduct" onSubmit={handleSubmitProduct}>
                            <div>
                                <label>Image: </label>
                                <input type="link" name="previewImage" />
                            </div>
                            <div>
                                <label>Description </label>
                                <textarea type="text" name="description" />
                            </div>
                            <div>
                                <label>Name </label>
                                <input type="text" name="name" />
                            </div>
                            <div>
                                <label>Price </label>
                                <input type="text" name="price" />
                            </div>
                            <div>
                                <label>CategoryUid </label>
                                <input type="id" name="categoryUid" />
                            </div>
                            <div>
                                <label>Characteristics </label>
                                <textarea type="text" name="characteristics" />
                            </div>
                            <button type="submit" value="PostCategory">Post</button>
                        </form>
                    </div>
                </div>
                <ul className='catalog__list'>
                    {categories.map((parentCategory) => (<ParentCategory
                        parentCategory={parentCategory}
                    />))}
                </ul>
            </div>
        </section>
    )
}