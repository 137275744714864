import './Calc.css';

export default function Calc() {
    return (
        <section id='Calc'>
            <div>
                <h2>Калькулятор комплектации мусоросброса</h2>
                <p>Используемые в этом калькуляторе значения объёма и веса могут отличаться от реальных. Точную информацию вы всегда можете узнать у наших менеджеров по телефону или электронной почте.</p>
                <form>
                    <div>
                        <label>Требуемая высота колонны мусорсброса:</label>
                        <input type="number" name="hightColumn" />
                    </div>

                    <div>
                        <label>Количество приёмных секций:</label>
                        <input type="number" name="quantitySection" />
                    </div>

                    <div>
                        <label>Количество кронштейнов:</label>
                        <input type="number" name="title" />
                    </div>

                    <div>
                        <label>Количество гасителей скорости:</label>
                        <input type="number" name="title" />
                    </div>

                    <button type="submit">Добавить все в корзину</button>
                </form>
            </div>
        </section>
    )
}