import './App.css';
import Header from "../Header/Header";
import Main from "../Main/Main";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";

import Services from "../Services/Services";
import About from "../About/About";
import Documentation from "../Documentation/Documentation";
import Contacts from "../Contacts/Contacts";
import Technologies from "../Technologies/Technologies";
import Delivery from '../Delivery/Delivery';
import Partners from '../Partners/Partners';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import Catalog from '../Catalog/Catalog';
import Category from '../Catalog/CategoryPage/CategoryPage';
import Admin from '../Admin/Admin';
import News from '../News/News';
import NewsPage from '../News/NewsPage/NewsPage';
import Cart from '../Cart/Cart';
import ProductPage from '../ProductPage/ProductPage';
import Calc from '../Calc/Calc';
import Gallery from '../Gallery/Gallery';



import MainApi from '../../utils/MainApi';


import { Navigate, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';


const cartFromLocalStorage = JSON.parse(localStorage.getItem('cart') || "[]");

function App() {
  const mainApi = new MainApi();

  const [news, setNews] = useState([])
  const [newsPage, setNewsPage] = useState([])

  const [categories, setCategories] = useState([])
  const [cart, setCart] = useState(cartFromLocalStorage);

  const [productData, setProductData] = useState(null)

  // ###___ADMIN

  // login

  function handleLogin(data) {
    const { username, password } = data;

    mainApi.signIn(username, password)
      .then(res => {
        if (res) {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  //submit category

  function onSubmitCategory(data) {
    const { previewImage, description, name, parentCategoryUid } = data;
    mainApi.createCategory(previewImage, description, name, parentCategoryUid)
      .then(res => {
        if (res) {
          console.log('success')
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  //submit product

  function onSubmitProduct(data) {
    const { previewImage, description, name, price, categoryUid, characteristics } = data;
    mainApi.addProduct(previewImage, description, name, price, categoryUid, characteristics)
      .then(res => {
        if (res) {
          console.log('success')
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  //submit news

  function onSubmitPost(data) {
    const { title, previewImage, description, content } = data;
    mainApi.createPost(title, previewImage, description, content)
      .then(res => {
        if (res) {
          console.log('success')
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  // Catalog

  function renderCatalog() {
    mainApi.getParentCategory()
      .then(res => {
        if (res) {
          setCategories(res);
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  // getProduct

  function getProduct(productUid) {
    mainApi.getProduct(productUid)
      .then(res => {
        if (res) {
          setProductData(res);
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  //render news

  function renderNews(page, size) {

    mainApi.getPosts(page, size)
      .then(res => {
        if (res) {
          setNews(res.posts);
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      });
  }

  function handleAddToCart(product) {
    const isAlreadyInCart = cart.some((item) => item.metadata.uid === product.metadata.uid);

    if (!isAlreadyInCart) {
      const newProduct = {
        ...product,
        quantity: 1,
      };
      setCart([...cart, newProduct]);
    } else {
      handlePlusToCart(product);
    }

  }

  function handlePlusToCart(product) {
    const newCart = cart.map((item) => {
      if (item.metadata.uid === product.metadata.uid) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }
      return item;
    });

    setCart(newCart);
  }

  function handleMinusFromCart(product) {
    const newCart = cart.map((item) => {
      if (item.metadata.uid === product.metadata.uid) {
        return {
          ...item,
          quantity: item.quantity - 1,
        };
      }
      return item;
    }).filter((item) => item.quantity > 0)

    setCart(newCart);
  }

  function placeOrder(customer, products, comment) {
    mainApi.placeOrder(customer, products, comment)
      .then(res => {
        if (res) {
          console.log('success')
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <div className="App">
      <Header
        cart={cart || []}
      />
      <Navigation />
      <main id='main'>
        <Routes>
          <Route path="/*" element={<Navigate to="/" replace />} />

          <Route
            path='/'
            element={
              <Main
                renderNews={renderNews}
                news={news}
                renderCatalog={renderCatalog}
                categories={categories}
              />
            }
          />

          <Route
            path='/Partners'
            element={
              <Partners />
            }
          />

          <Route
            path='/Services'
            element={
              <Services />
            }
          />

          <Route
            path='/Calc'
            element={
              <Calc />
            }
          />

          <Route
            path='/Delivery'
            element={
              <Delivery />
            }
          />

          <Route
            path='/About'
            element={
              <About />
            }
          />

          <Route
            path='/Documentation'
            element={
              <Documentation />
            }
          />

          <Route
            path='/Contacts'
            element={
              <Contacts />
            }
          />

          <Route
            path='/Gallery'
            element={
              <Gallery />
            }
          />


          <Route
            path='/Technologies'
            element={
              <Technologies />
            }
          />

          <Route
            path='/PrivacyPolicy'
            element={
              <PrivacyPolicy />
            }
          />

          <Route
            path='/Cart'
            element={
              <Cart
                cart={cart || []}
                handlePlusToCart={handlePlusToCart}
                handleMinusFromCart={handleMinusFromCart}
                placeOrder={placeOrder}
                setCart={setCart}
              />
            }
          />

          <Route
            path='/ProductPage/:productId'
            element={
              <ProductPage
                getProduct={getProduct}
                data={productData}
                cart={cart}
                handleAddToCart={handleAddToCart}
                handlePlusToCart={handlePlusToCart}
                handleMinusFromCart={handleMinusFromCart} />
            }
          />

          <Route
            path='/Catalog'
            element={
              <Catalog
                renderCatalog={renderCatalog}
                categories={categories}
              />
            }
          />

          <Route
            path='/Catalog/:categoryId'
            element={
              <Category
                handleAddToCart={handleAddToCart}
                cart={cart}
                handlePlusToCart={handlePlusToCart}
                handleMinusFromCart={handleMinusFromCart}
              />
            }
          />

          <Route
            path='/News'
            element={
              <News
                renderNews={renderNews}
                news={news}
                setNewsPage={setNewsPage}
                newsPage={newsPage}
              />
            }
          />

          <Route
            path='/News/:postId'
            element={
              <NewsPage
                data={newsPage}
              />
            }
          />

          <Route
            path='/Dev'
            element={
              <Admin
                onLogin={handleLogin}
                onSubmitCategory={onSubmitCategory}
                onSubmitProduct={onSubmitProduct}
                onSubmitPost={onSubmitPost}
                renderCatalog={renderCatalog}
                categories={categories}
              />
            }
          />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
