//import './Catalog.css';
import './Catalog2.css';

import { useEffect } from "react";
import { Link } from "react-router-dom";

function ParentCategory2({ parentCategory }) {

    return (
        <Link className='noDecoration catalog2__card' to={`/Catalog/${parentCategory.metadata.uid}`} >
                <div className="catalog2__image_box">
                    <img src={parentCategory.previewImage} className="catalog2__image" alt={parentCategory.name} />
                </div>
                <div className="catalog2__content">
                    <p className="catalog2__title">{parentCategory.name}</p>
                </div>
        </Link>
    )
}

export default function Catalog({ renderCatalog, categories }) {
    useEffect(() => {
        renderCatalog();
    }, []);

    return (
        <section id="Сatalog" className='catalog2'>
            <div className='catalog2__list'>
                {categories.map((parentCategory) => (<ParentCategory2
                    key={parentCategory.metadata.uid}
                    parentCategory={parentCategory}
                />))}
            </div>
        </section>
    )
}
