import './News.css';
import NewsPanel from './NewsPanel/NewsPanel';
import './NewsPanel/NewsPanel';

import { useEffect } from "react";

export default function News({ renderNews, news, setNewsPage }) {
    useEffect(() => {
        renderNews(1, 100);
    }, []);
    console.log(news);

    return (
        <section id="news" className='news'>
            <ul className='news__list'>
            {news.map((item) => (<NewsPanel 
                key={item.metadata.uid}
                item={item}
                setNewsPage={setNewsPage}
            />))}
            </ul>
        </section>
    )
}
