import './CategoryPage.css';
import './CategoryPage_Block_Product.css';
import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MainApi from '../../../utils/MainApi';
import MarkdownSpan from "../../MarkdownSpan/MarkdownSpan";

function Product({ productData, handleAddToCart, cart, handlePlusToCart, handleMinusFromCart }) {

    const isInCart = !!cart.length && !!productData && !!cart.find((item) => item.metadata.uid === productData.metadata.uid);
    const quantity = isInCart ? cart.find((item) => item.metadata.uid === productData.metadata.uid).quantity : 0;

    function addToCart() {
        handleAddToCart(productData)
    }

    return (
        <div>
            <div className='productBlockCard'>
                <Link to={`/productPage/${productData.metadata.uid}`} target='_blank'>
                    <div className='productCardImageBox'>
                        <img src={productData.previewImage} alt={productData.name} className='productCardImage' />
                    </div>
                </Link>

                <div className='productCardPrice'>
                    <div className='productCardDescription'>
                        <p>{productData.name}</p>
                    </div>
                    {!isInCart && <button onClick={addToCart} className='productCardButton'>В корзину</button>}
                    {isInCart && <div className='productCardItemCount'>
                        <button onClick={() => handleMinusFromCart(productData)}>-</button>
                        <p>{quantity}</p>
                        <button onClick={() => handlePlusToCart(productData)}>+</button>
                    </div>}
                    <p>{productData.price} р.</p>
                </div>
            </div>



            {/*<hr className='productPageHr' />
            <div className='productCard'>
                <Link to={`/productPage/${productData.metadata.uid}`}>
                    <div className='productCardImageBox'>
                        <img src={productData.previewImage} alt={productData.name} className='productCardImage' />
                    </div>
                </Link>

                <div className='productCardDescription'>
                    <p>{productData.name}</p>
                </div>

                <div className='productCardPrice'>
                    <p>{productData.price} р.</p>
                    {!isInCart && <button onClick={addToCart} className='productCardButton'>В корзину</button>}
                    {isInCart && <div className='productCardItemCount'>
                        <button onClick={() => handleMinusFromCart(productData)}>-</button>
                        <p>{quantity}</p>
                        <button onClick={() => handlePlusToCart(productData)}>+</button>
                    </div>}
                </div>
            </div>*/}
        </div>
    )
}

function UnderCategory({ underCategoryData, handleAddToCart, cart, handlePlusToCart, handleMinusFromCart }) {
    const [productsData, setProductsData] = useState([]);
    const mainApi = new MainApi();

    useEffect(() => {
        mainApi.getProductByParentUid(underCategoryData.metadata.uid, 1, 50)
            .then(setProductsData)
    }, []);


    return (
        <div className='underCategory'>
            <hr className='categoryPageHr' />
            <div className='underCategoryTitle'>
                <img src={underCategoryData.previewImage} alt={underCategoryData.name} className='underCategoryImg'></img>
                <div className='categoryPageDescription'>
                    <h2>{underCategoryData.name}</h2>
                    <br />
                    <p><MarkdownSpan markdownText={underCategoryData.description ? underCategoryData.description : ' '} /></p>
                </div>
            </div>
            <div className='product_blocks'>
                {productsData?.products?.map((productData) => (<Product
                    key={productData.metadata.uid}
                    productData={productData}
                    handleAddToCart={handleAddToCart}
                    cart={cart}
                    handlePlusToCart={handlePlusToCart}
                    handleMinusFromCart={handleMinusFromCart}
                />))}
            </div>
        </div>
    )
}


export default function Category({ handleAddToCart, cart, handlePlusToCart, handleMinusFromCart }) {
    const [displayCaregory, setDisplayCaregory] = useState([]);
    const [displayUnderCategories, setdisplayUnderCategories] = useState([])

    const { categoryId } = useParams();
    const mainApi = new MainApi();

    useEffect(() => {
        mainApi.getCategory(categoryId)
            .then((res) => {
                setDisplayCaregory(res);
            })

    }, []);

    useEffect(() => {
        mainApi.getCategoryByParentUid(categoryId)
            .then(setdisplayUnderCategories)
    }, []);



    return (
        <div className='categoryPage'>
            <div className='categoryPageHead'>
                <div className='categoryPageDescription'>
                    <h1>{displayCaregory.name}</h1>
                    <br />
                    <p><MarkdownSpan markdownText={displayCaregory.description ? displayCaregory.description : ' '} /></p>
                </div>

                <div>
                    <img src={displayCaregory.previewImage} className='categoryPageImg' alt={displayCaregory.name} />
                </div>
            </div>
            {displayUnderCategories.map((underCategoryData) => (<UnderCategory
                key={underCategoryData.metadata.uid}
                underCategoryData={underCategoryData}
                handleAddToCart={handleAddToCart}
                cart={cart}
                handlePlusToCart={handlePlusToCart}
                handleMinusFromCart={handleMinusFromCart}
            />))}
        </div>
    )
}
