import './Documentation.css' 

export default function Documentation() {
    return (
        <section id="documentation">
            <div className="documentation">
                <h1>Сертификаты и инструкции</h1>
                <br/>
                <p>Товары, представленные на сайте нашей компании, безопасны и имеют все необходимые разрешительные документы.</p>
                <div className="rows">
                    <div className="row">
                        <div className="col-md-4">
                            <h4>Мойки колёс для стройплощадок</h4>
                            <p>
                                <a href="/media/Docs/washers_cert.pdf" target="_blank" className='link doc_link'>Сертификат соответствия</a>
                                <span className="infobadge">: до 22 марта 2026</span>
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h4>Секционный полимерный мусоросброс</h4>
                            <p> 
                                <a href='/media/Docs/sert_mussi_soot_b.pdf'  target="_blank"  className='link doc_link'>Сертификат соответствия</a>
                                <span className="infobadge">: до 05 июня 2025</span>
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h4>Игровое оборудование</h4>
                            <p>
                                <a href="/media/Docs/certs_playcomplex_td.pdf" rel="lightbox" target="_blank" className='link doc_link'>Сертификат соответствия</a>
                                <span className="infobadge">: до 03 февраля 2025</span>
                                <br />
                                <a href="/media/Docs/playgrounds-sanit.pdf" rel="lightbox" target="_blank" className='link doc_link'>Санитарный сертификат</a>
                                <span className="infobadge">: бессрочно</span>
                                <br />
                                <a href="/media/Docs/otkaznoe_pog-bez_str-01-02.pdf" rel="lightbox" target="_blank" className='link doc_link'>Отказное письмо о пожарной безопасности</a>
                                <span className="infobadge">: бессрочно</span>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <h4>Общие - на полиэтиленовые изделия</h4>
                            <p>
                                <a href="/media/Docs/sertifikat_sootvetstvia_na_emkosti.pdf" rel="lightbox" target="_blank" className='link doc_link'>Сертификат соответствия</a>
                                <span className="infobadge">: до 04.06.2021 (PDF 445,58 кБ)</span>
                            </p>

                            <p>
                                <a href="/media/Docs/deklaraсiya_do_2023_goda.pdf" rel="lightbox" target="_blank" className='link doc_link'>Декларация соответствия</a>
                                <span className="infobadge">: до 27.06.2023 (PDF 437,54 кБ)</span>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <h4>Туалетные модули</h4>
                            <p>
                                <a href="/media/Docs/sertif_tual_modul_b1.pdf" rel="lightbox" target="_blank" className='link doc_link'>Сертификат соответствия</a>
                                <span className="infobadge">: до 13 июля 2017</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}