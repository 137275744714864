import './Delivery.css'

export default function Delivery() {
    return (
        <section>
            <div className='delivery'>
                <h1>ДОСТАВКА</h1>
                <br />
                <h3>МЫ ДОСТАВЛЯЕМ НАШУ  ПРОДУКЦИЮ В ЛЮБУЮ ТОЧКУ РОССИИ, СНГ, СТРАНАМ  БЛИЖНЕГО И ДАЛЬНЕГО ЗАРУБЕЖЬЯ</h3>
                <br />

                <p>Компания ООО «Экопром-Строй » осуществляет оперативную и недорогую доставку продукции в любой регион России, СНГ, странам ближнего и дальнего зарубежья. Благодаря слаженной работе собственной логистической службы, сотрудничеству со всеми транспортными компаниями и широким спектром автопартнеров в регионах, наша доставка будет быстрой и надежной.
                При необходимости организуем срочную авиадоставку.</p>
                <br />
                <br />
                <p>Оплата за доставку может быть включена в стоимость продукции, выделена отдельным пунктом с учетом НДС. Возможна оплата стоимости доставки непосредственно выбранной Вами транспортной компании.
                Наша логистическая служба рассчитает стоимость и сроки доставки продукции в любую точку России, СНГ,странам ближнего и дальнего зарубежья.</p>

            </div>
        </section>
    )
}