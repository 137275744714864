import "./Gallery.css";
import { useState, useEffect } from 'react';
import placeholder from '../../images/Catalog.placeholder.png'
import { galleryImgCount } from '../../utils/constants.js';


export default function Gallery() {
    const [isOpened, setIsOpened] = useState(false);
    const [img, setImg] = useState();

    useEffect(() => {
        document.addEventListener('keydown', function (event) {
            if (event.code === 'Escape') {
                setIsOpened(false)
            }
            if (event.code === 'ArrowLeft') {
                plusSlides(-1)
            }
            if (event.code === 'ArrowRight') {
                plusSlides(1)
            }
        })
    }, [])

    const plusSlides = (n) => {
        setImg((prevIndex) => ((prevIndex + n) <= 0) ? galleryImgCount : (prevIndex + n) && ((prevIndex + n) > galleryImgCount) ? 1 : (prevIndex + n));
    }

    const keyDown = (event) => {
        console.log(event.key);
    };

    function ImgBox() {
        let content = [];
        for (let i = 1; i < (galleryImgCount + 1) ; i++) {
            content.push(
                <div className="galleryImgBox" key={i}>
                    <img className="galleryImg" src={`/media/Gallery/Mini/galleryImg%20%28${i}%29.png`} alt={i} onClick={() => {
                        setIsOpened(true);
                        setImg(i);
                    }} />
                </div>
            );
        };
        return (
            content
        )
    }

    function popupImg() {
        return (
            (isOpened) && <div className="popupBox">
                <div className="popupImg-container">
                    <figure className="popup__figure">
                        <button type="button" className="popup__button-close" onClick={() => {
                            setIsOpened(false);
                        }}></button>
                        <img src={`/media/Gallery/Full/galleryImg%20%28${img}%29.png`} alt={"Горка"} className="popup__img" />
                        <button className="prev" onClick={() => plusSlides(-1)}>&#10094;</button>
                        <button className="next" onClick={() => plusSlides(1)}>&#10095;</button>
                    </figure>
                </div>
            </div>
        )
    }

    return (
        <section id="gallery" onKeyDown={keyDown}>
            <div className="gallery">
                <h1>Фотогалерея горок и игровых комплексов</h1>
                <div className="galleryImgGridBox">
                    {
                        ImgBox()
                    }

                    {
                        popupImg()
                    }

                </div>
            </div>
        </section>
    )
}